
















import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { EarlyWarningHeaders } from './model/EarlyWarningHeaders';
import {
  EarlyWarningColumns,
  EarlyWarningLevelIndexToKeyMap
} from './model/EarlyWarning.model';
import { EarlyWarningService } from '@/shared/services/early-warning/early-warning.service';
import store, { DivisionModule } from '@/store/index';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable }
})
export default class Inventory extends Vue {
  columns: TableHeaders[] = EarlyWarningHeaders;
  rows = [];
  loading = true;

  created() {
    this.getConfig();

    store.watch(
      (state: any) => state.division.division,
      () => this.getConfig()
    );
  }

  // get isLastPage(): boolean {
  //   return !DivisionModule.meta.lastKey;
  // }

  async getConfig() {
    this.rows = [];
    this.loading = true;
    const config = await EarlyWarningService.getConfig(DivisionModule.division);

    config.data.forEach(i => {
      const item = {
        types: i.types,
        category: i.category,
        condition: i.condition,
        option: i.option,
        to: i.to,
        from: i.from,
        fields: i.fields?.join(', '),
        id: i.id,
        import: false,
        export: false,
        van: false,
        cityToCity: false,
        bareChassis: false
      };

      i.types?.forEach(type => {
        item[type.name] = type.editable ? type.value : 'disabled';
      });

      i.levels.forEach((level, index) => {
        item[EarlyWarningLevelIndexToKeyMap[index]] = `${level}`;
      });

      this.rows.push(item);
    });
    this.loading = false;
  }

  async onAction({ key, data }) {
    if (key !== 'update') {
      return;
    }

    const levelKeys = ['levelOne', 'levelTwo', 'levelThree', 'levelFour'];

    const config = {
      category: data.category,
      condition: data.condition,
      to: data.to,
      option: data.option,
      from: data.from,
      fields: data.fields.length ? data.fields?.split(', ') : [],
      levels: levelKeys.map(key => {
        return data[key]?.length ? parseInt(data[key]) : null;
      }),
      types: data.types
    };

    config.types.forEach(
      type =>
        (type.value = data[type.name] === 'disabled' ? false : data[type.name])
    );

    await EarlyWarningService.updateConfig(data.id, config);
  }
}
