import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

export const EarlyWarningHeaders: TableHeaders[] = [
  {
    key: 'IM',
    label: `IM`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'EX',
    label: `EX`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'VA',
    label: `VA`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'CY',
    label: `CY`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'BA',
    label: `BA`,
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'category',
    label: `Category`
  },
  {
    key: 'condition',
    label: `Condition`
  },
  {
    key: 'to',
    label: `To`
  },
  {
    key: 'option',
    label: `Option`
  },
  {
    key: 'from',
    label: `From`
  },
  // {
  //   key: 'fields',
  //   label: `BC Detail Fields`
  // },
  {
    key: 'levelOne',
    label: `LVL.1`,
    content: {
      type: CellContentType.Number,
      skipValidation: true
    }
  },
  {
    key: 'levelTwo',
    label: `LVL.2`,
    content: {
      type: CellContentType.Number,
      skipValidation: true
    }
  },
  {
    key: 'levelThree',
    label: `LVL.3`,
    content: {
      type: CellContentType.Number,
      skipValidation: true
    }
  },
  {
    key: 'levelFour',
    label: `LVL.4`,
    content: {
      type: CellContentType.Number,
      skipValidation: true
    }
  },
  {
    key: 'update',
    label: `Update`,
    content: {
      type: CellContentType.Button,
      label: 'Update'
    }
  }
];
