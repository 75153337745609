import { TableItem } from './../../../../shared/components/table/models/tableHeaders.model';
export interface EarlyWarning extends TableItem {
  import: boolean | 'inactive';
  export: boolean | 'inactive';
  van: boolean | 'inactive';
  cityToCity: boolean | 'inactive';
  bareChassis: boolean | 'inactive';
  category: string;
  hideCategoryLabel?: boolean;
  condition: string;
  option: EarlyWarningOption;
  from: string;
  to: string;
  levelOne: number;
  levelTwo: number;
  levelThree: number;
  levelFour: number;
}

export const EarlyWarningLevelIndexToKeyMap = {
  ['0']: 'levelOne',
  ['1']: 'levelTwo',
  ['2']: 'levelThree',
  ['3']: 'levelFour'
};

export const EarlyWarningTypesToKeyMap = {
  ['EX']: 'levelOne',
  ['1']: 'levelTwo',
  ['2']: 'levelThree',
  ['3']: 'levelFour'
};

export enum EarlyWarningOption {
  Left = 'Left',
  Passed = 'Passed'
}

export enum EarlyWarningCondition {
  NoSchedule = 'No Schedule',
  NoPOSchedule = 'No P/O  Schedule',
  NoPOActualIn = 'No P/O  Actual In',
  NoPODriver = 'No P/O Driver',
  NoPOActual = 'No P/O Actual',
  NoDILVSchedule = 'No DILV Schedule',
  NoDILVActualIn = 'No DILV Actual In',
  NoDILVDriver = 'No DILV Driver',
  NoPOD = 'No POD',
  NoPOL = 'No POL',
  NoPUSchedule = 'No P/U Schedule',
  NoPUActualIn = 'No P/U Actual In',
  NoPUDriver = 'No P/U Driver',
  NoReturn = 'No Return',
  NoRTNActualIn = 'No RTN Actual In',
  NoDriver = 'No Driver'
}

export enum EarlyWarningField {
  CurrentDateTime = 'Current Date&Time',
  ETA = 'ETA',
  POScheduled = 'P/O Scheduled',
  LFD = 'LFD',
  Today = 'Today',
  DILVSchedule = 'DILV Schedule',
  MTYPUDate = 'MTY P/U Date',
  RFD = 'RFD',
  RTNSchedule = 'RTN Schedule',
  WOCreate = 'WO# Create',
  IMPDILVActualOut = 'IMP DILV Actual Out',
  EXPPUActualOut = 'EXP P/U Actual Out',
  DILVActualIn = 'DILV Actual In'
}

export const EarlyWarningColumns: EarlyWarning[] = [
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'WO# Create',
    condition: 'No Schedule',
    to: 'Current Date&Time',
    from: 'WO# Create',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'ETA For Vessel',
    condition: 'No P/O  Schedule',
    to: 'ETA',
    from: 'Current Date&Time',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    disableFields: { van: true, cityToCity: true, bareChassis: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'ETA For Vessel',
    condition: 'No P/O  Schedule',
    to: 'Current Date&Time',
    from: 'ETA',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    disableFields: { van: true, cityToCity: true, bareChassis: true },
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pull Out',
    condition: 'No P/O  Actual In',
    to: 'P/O Scheduled',
    from: 'Current Date&Time',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pull Out',
    condition: 'No P/O  Actual In',
    to: 'Current Date&Time',
    from: 'P/O Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pull Out',
    condition: 'No P/O Driver',
    to: 'P/O Schedule',
    from: 'Current Date&Time',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pull Out',
    condition: 'No P/O Driver',
    to: 'Current Date&Time',
    from: 'P/O Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Demurrage',
    condition: 'No P/O  Schedule',
    to: 'LFD',
    from: 'Current Date&Time',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Demurrage',
    condition: 'No P/O Driver',
    to: 'Current Date&Time',
    from: 'LFD',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Demurrage',
    condition: 'No P/O Actual',
    to: 'LFD',
    from: 'Current Date&Time',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Demurrage',
    condition: 'No P/O Actual',
    to: 'Current Date&Time',
    from: 'LFD',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No DILV Schedule',
    to: 'Today',
    from: 'P/O Actual In',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No DILV Actual In',
    to: 'DILV Schedule',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No DILV Actual In',
    to: 'Today',
    from: 'DILV Schedule',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No DILV Driver',
    to: 'DILV Schedule',
    from: 'Today',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No DILV Driver',
    to: 'Today',
    from: 'DILV Schedule',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No POD',
    to: 'Today',
    from: 'IMP DILV Actual Out',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: { export: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Delivery',
    condition: 'No POL',
    to: 'Today',
    from: 'EXP P/U Actual Out',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: {
      import: true,
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Schedule',
    to: 'Today',
    from: 'DILV Actual In',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Schedule',
    to: 'MTY P/U Date',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Schedule',
    to: 'MTY P/U Date',
    from: 'Today',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Actual In',
    to: 'P/U Schedule',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Actual In',
    to: 'Today',
    from: 'P/U Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Driver',
    to: 'P/U Schedule',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Pick UP',
    condition: 'No P/U Driver',
    to: 'Today',
    from: 'P/U Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Per Diem',
    condition: 'No Return',
    to: 'RFD',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Per Diem',
    condition: 'No Return',
    to: 'Today',
    from: 'RFD',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true },
    disableFields: {
      van: true,
      cityToCity: true,
      bareChassis: true
    }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Return',
    condition: 'No RTN Actual In',
    to: 'RTN Schedule',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Return',
    condition: 'No RTN Actual In',
    to: 'Today',
    from: 'RTN Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Return',
    condition: 'No Driver',
    to: 'RTN Schedule',
    from: 'Today',
    option: EarlyWarningOption.Left,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  },
  {
    import: false,
    export: false,
    van: false,
    cityToCity: false,
    bareChassis: false,
    category: 'Return',
    condition: 'No Driver',
    to: 'Today',
    from: 'RTN Schedule',
    option: EarlyWarningOption.Passed,
    levelOne: null,
    levelTwo: null,
    levelThree: null,
    levelFour: null,
    hideLabels: { category: true }
  }
];

// disableFields: { van: true, cityToCity: true, bareChassis: true },
//     hideLabels: { category: true }
